import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate, graphql, StaticQuery } from "gatsby";
import Button from "./Button";
import LottieHeader from "./LottieHeader";
import { GlobalContext } from "../providers/GlobalProvider";
import { gsap, Power2 } from "gsap";

import "../css/header.css";
export default function Header({ logoColor, scrollAnimation }) {
  const { pageScrolled, pageScrollUp } = useContext(GlobalContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const nav = useRef();
  const navSelectors = gsap.utils.selector(nav);

  const mobileItems = useRef([]);
  const [flexNavShow, setFlexNavShow] = useState(false);

  const openMenu = () => {
    setMenuOpen(!menuOpen);

    if (!menuOpen) {
      document.getElementById("page-content").className = "fullHeightNav";
      nav.current.style.backgroundColor = "#141414";
      window.document.body.className = "removeScroll";
      setFlexNavShow(false);
      var tlMenu = gsap.timeline();
      tlMenu.set(navSelectors(".menu-timeline .before-span"), {
        y: 120,
        opacity: 0,
      });

      mobileItems.current.forEach((element, index) => {
        tlMenu.to(
          element,
          {
            duration: 0.7,
            force3D: true,
            y: 0,
            opacity: 1,
            delay: 0.4,
            ease: Power2.easeOut,
          },
          index * 0.1
        );
      });
    } else {
      setFlexNavShow(true);
      var tlMenu2 = gsap.timeline();
      mobileItems.current.forEach((element, index) => {
        tlMenu2.to(
          element,
          0.5,
          {
            force3D: true,
            y: -120,
            opacity: 0,
            delay: 0,
            ease: Power2.easeIn,
          },
          index * 0.05
        );
      });

      setTimeout(() => {
        document.getElementById("page-content").className = "";
        window.document.body.className = "";
      }, 1000);
    }
  };

  const delayAndGo = (e, route) => {
    e.preventDefault();
    setTimeout(() => {
      navigate(route);
    }, 800);
  };

  return (
    <div>
      <header
        className={`fullscreen-menu ${pageScrolled ? "scrolled" : ""} ${
          pageScrollUp ? "scrollUp" : ""
        } ${menuOpen ? "over-sidebar over-white-section" : ""}`}
      >
        <StaticQuery
          query={graphql`
            {
              allContentfulComponent(
                filter: { section: { eq: "header" } }
                sort: { fields: order }
              ) {
                nodes {
                  link
                  slug
                  headline
                }
              }
            }
          `}
          render={(data) => (
            <div
              className="header-child"
              id="header-container"
            >
              <nav className={`${menuOpen ? "open" : ""}`} ref={nav}>
                <div className="nav-height">
                  <div className="outer">
                    <div className="inner">
                      <ul
                        data-breakpoint="10025"
                        className={`flexnav  flexnav menu with-js sm-screen ${
                          menuOpen ? "over-sidebar over-white-section" : ""
                        } ${flexNavShow ? "flexnav-show" : ""} `}
                      >
                        {data.allContentfulComponent.nodes.map((menu, item) => (
                          <li className="link menu-timeline" key={menu.slug}>
                            <Link
                              to={menu.link}
                              key={menu.slug}
                              className="ajax-link"
                              onClick={(e) => {
                                delayAndGo(e, menu.link);
                                openMenu();
                              }}
                            >
                              <div
                                className="before-span"
                                ref={(el) => (mobileItems.current[item] = el)}
                              >
                                <span data-hover="Home">{menu.headline}</span>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="menu-wrap">
                <div className="button-wrap right menu disable-drag">
                  <div className="icon-wrap parallax-wrap">
                    <div className="button-icon parallax-element">
                      <div
                        id="burger-wrapper"
                        onClick={openMenu}
                        onKeyDown={openMenu}
                        role="presentation"
                      >
                        <div
                          id="menu-burger"
                          className={`${menuOpen ? "open" : ""}`}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <span className="touch-button">
                          <i className="navicon">▼</i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="button-text sticky right">
                    <span data-hover="Menu">Menu</span>
                  </div>
                </div>

                {data.allContentfulComponent.nodes.map((menu, i) => (
                  <div className="large-menu-right"  key={menu.slug}>
                    <Link
                      className="ajax-link"
                      activeClassName="active-menu"
                      id={menu.slug}
                      key={menu.slug}
                      data-type="page-transition"
                      to={menu.link}
                    >
                      <span className={`${i === (data.allContentfulComponent.nodes.length - 1) ? 'last-menu' : ''}`}>{menu.headline}</span>
                    </Link>
                  </div>
                ))}

                <a
                  target="_blank"
                  href="https://app.hyped.com/"
                  rel="noreferrer"
                >
                  <div className="client-button">
                    <Button text="Client Login" color="#fff" />
                  </div>
                </a>
              </div>
            </div>
          )}
        ></StaticQuery>

        <LottieHeader logoColor={logoColor} scrollAnimation={scrollAnimation} />
      </header>
    </div>
  );
}
