import React, { useEffect, useRef, useContext } from "react";
import { gsap, Power2 } from "gsap";
import "../css/cursor.css";
import { GlobalContext } from "../providers/GlobalProvider";


const Cursor = () => {
    const { cursor, contactCursor, footerCursor } = useContext(GlobalContext);
    const ball = useRef();
    const ball_loader = useRef();
    let moveTween = useRef(),
        stateTween = useRef();

    useEffect(() => {
        addEventListeners();
        moveTween.current = gsap.to(ball.current, { duration: 0.4, paused: true });
        return () => removeEventListeners();
    }, []);

    const addEventListeners = () => {
        document.addEventListener("mousemove", handleMouseEvent);
    }

    const removeEventListeners = () => {
        document.removeEventListener("mousemove", handleMouseEvent);
    };

    const handleMouseEvent = (t) => {
        var a = window.pageYOffset || document.documentElement.scrollTop;

        moveTween.current.vars.x = t.pageX - 40;
        moveTween.current.vars.y = t.pageY - 40 - a;

        moveTween.current.invalidate().restart();
    }

    const handleMouseEnter = () => {
        stateTween.current && stateTween.current.kill();
        stateTween.current = gsap
        .timeline()
        .to(ball.current, {
            duration: 0.3,
            borderWidth: "2px",
            scale: 1.5,
            borderColor: "#999999",
            backgroundColor: "#999999",
            opacity: ".25"
        })
        .to(ball_loader.current, {
            duration: 0.2,
            borderWidth: "2px",
            top: 2,
            left: 2
        }, 0);
    };
    
    const handleMouseLeave = () => {
        stateTween.current && stateTween.current.kill();
        stateTween.current = gsap
          .timeline()
          .to(ball.current, {
            duration: 0.2,
            borderWidth: "4px",
            scale: 0.5,
            opacity: "1",
            borderColor: "#999999",
            backgroundColor: "transparent"
          })
          .to(
            ball_loader.current,
            {
              duration: 0.2,
              borderWidth: "4px",
              top: 0,
              left: 0
            },
            0
        );
    };

    useEffect(() => {
        if (cursor.active) {
            handleMouseEnter();
        } else {
            handleMouseLeave();
        }
    }, [cursor]);

    const handleContactMouseEnter = () => {
        stateTween.current && stateTween.current.kill();
        stateTween.current = gsap
        .timeline()
        .to(ball.current, {
            duration:.3,
            scale:1.35,
            borderWidth:"2px",
            backgroundColor: "transparent",
            borderColor: "#000",
            opacity:1
        })
    };

    const handleFooterMouseEnter = () => {
        stateTween.current && stateTween.current.kill();
        stateTween.current = gsap
        .timeline()
        .to(ball.current, {
            duration:.5,
            scale:.9,
            opacity:1,
            borderWidth:"2px",
            borderColor:"#000",
        })
    };

    useEffect(() => {
        if (contactCursor) {
            handleContactMouseEnter();
        } else if (footerCursor) {
            handleFooterMouseEnter();
        } else {
            handleMouseLeave();
        }
    }, [contactCursor, footerCursor]);

    
    return (
        <div className="animated-cursor">
            <div className="cd-cover-layer"></div>
                <div id="magic-cursor">
                    <div id="ball" ref={ball}>
                        <div id="ball-drag"></div>
                        <div id="ball-loader" ref={ball_loader}></div>
                    </div>
                </div>
                <div id="clone-image"></div>
            <div id="rotate-device"></div>
        </div>
    );
}

export default Cursor;