import React, { useEffect, useState, useContext, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import { GlobalContext } from "../providers/GlobalProvider";

import favicon from "../images/HYPED_Favicon.png";
import Header from "./Header";
import Footer from "./Footer";
import Prefooter from "./Prefooter";
import Preloader from "./Preloader";
import Cursor from "./Cursor";

//animations
import { gsap, Power2 } from "gsap";

export default function Layout({ children, noPrefooter, bgColor }) {
  const [scrollAnimation, setScrollAnimation] = useState(false);
  const [logoColor, setLogoColor] = useState(false);
  const { setPageScrolled, setPageScrollUp } = useContext(GlobalContext);

  const app = useRef();
  const [prev, setPrev] = useState();

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) return;

    const exp_section = app.current.querySelector('.expertise-container');
    const port_section = app.current.querySelector('.portfolio-sec');

    if (exp_section) {
      var sectionTop = exp_section.getBoundingClientRect().top;
      var sectionBottom = port_section.getBoundingClientRect().bottom;
      var invert = sectionTop <= 50 && sectionBottom >= 60;
      if (invert) setLogoColor(true);
      else setLogoColor(false);
    }
    const handleScroll = (e) => {
      if(window.scrollY <= 0){
        return;
      }
      
      const scrollDir = window.scrollY;
      if (scrollDir > 0) {
        setPageScrolled(true);
      }
      setPageScrollUp(prev > scrollDir);
      setPrev(scrollDir);
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll);
    };

  }, [prev]);

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) return;

    const handleScrollAnimation = (e) => {
      const isScrolled = window.scrollY > 70;
      if (isScrolled !== scrollAnimation) {
        setScrollAnimation(!scrollAnimation);
      }
    };

    document.addEventListener("scroll", handleScrollAnimation, {
      passive: true,
    });
    return () => {
      document.removeEventListener("scroll", handleScrollAnimation);
    };
  }, [scrollAnimation]);

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) return;

    var controller = new window.ScrollMagic.Controller();

    let hasAnimation = window.document.querySelectorAll(".has-animation");
    hasAnimation.forEach((ele) => {
      let $thisHeight = ele.clientHeight;

      var scene = new window.ScrollMagic.Scene({
        triggerElement: ele,
        duration: $thisHeight,
      }).addTo(controller);

      scene.triggerHook(1);

      scene.on("enter", function () {
        setTimeout(() => {
          gsap.to(ele, {
            duration: 0.6,
            force3D: true,
            opacity: 1,
            y: 0,
            scale: 1,
            delay: 0,
            ease: Power2.easeOut,
          });
          ele.classList.add("animated");
        }, ele.getAttribute("data-delay"));
      });
    });
  }, []);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  return (
    <div className="hyped-layout">
      <Helmet
        title={site.siteMetadata.title}
        defaultTitle={site.siteMetadata.title}
      >
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <main>
        <Preloader />
        <div className="cd-index cd-main-content">
          <div id="page-content" data-bgcolor="#fff">
            <Header logoColor={logoColor} scrollAnimation={scrollAnimation} />
            <div className="hyped-container" ref={app}>
              <div id="app" ></div>
              {children}
            </div>
          </div>
        </div>
      </main>
      {!noPrefooter && <Prefooter />}
      <Footer bgColor={bgColor} />
      <Cursor />
    </div>
  );
}
