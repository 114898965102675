import React, { useRef, useEffect, useState, useContext, useCallback } from "react";
import { Link } from "gatsby";
import Lottie from "react-lottie";
import animationData from "../lotties/lottie-scroll_logo.json";
import { GlobalContext } from "../providers/GlobalProvider";

import "../css/header.css";

export default function LottieHeader({ scrollAnimation, logoColor }) {
  const logoAnimation = useRef();
  const { pageScrolled, setPageScrollUp, resetLottieLogo, setResetLottieLogo } = useContext(GlobalContext);

  const [isStopped, setIsStopped] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  // const [show, setShow] = useState(true);

  const options = {
    animationData: animationData,
    loop: false,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const { anim } = logoAnimation.current;

    if (pageScrolled) {
      if (scrollAnimation) {
        setIsPaused(false);
        setIsStopped(false);
        anim.playSegments([0, 90], true);
      } else {
        anim.playSegments([120, 180], true);
      }
    }
  }, [scrollAnimation]);

  useEffect(() => {
    const { anim } = logoAnimation.current;

    if (resetLottieLogo) {
      setIsPaused(false);
      setIsStopped(false);
      anim.playSegments([170, 180], true);
    }
  }, [resetLottieLogo]);

  return (
    <div>
      <div id="logo" className="hide-ball disable-drag">
        <Link to="/" onClick={() => { setPageScrollUp(true); setResetLottieLogo(true) }}>
          <div id="logo-lottie-default">
            <div id="lottie-logo-scroll-default" className={`${logoColor ? "scrolledLogo" : ""}`}>
              <Lottie
                options={options}
                ref={logoAnimation}
                isStopped={isStopped}
                isPaused={isPaused}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
