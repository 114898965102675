import React, { useRef, useContext } from 'react';
import { gsap, Power2 } from "gsap";
import { GlobalContext } from "../providers/GlobalProvider";
import "../css/footer.css";


export default function Footer(props) {
  function scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const iconLeft = useRef();
  const stateTween = useRef();
  const item = useRef();
  const wrap = useRef();

  const { setFooterCursor } = useContext(GlobalContext);

  const animateOn = () => {
    setFooterCursor(true)
  }

  const animateOut = () => {
    setFooterCursor(false)
  }

  const handleLeftParallax = (event) => {
    let parallaxContainer = document.getElementsByClassName('.icon-wrap.parallax-wrap'),
        parallaxItem = document.getElementsByClassName('.button-icon.parallax-element'),
        r = iconLeft.current.getBoundingClientRect(),
        i = event.pageX - r.left,
        n = event.pageY - r.top -d,
        d = window.pageYOffset||document.documentElement.scrollTop,
        speed = 3,
        posx = r.width - (i * speed) / 100,
        posy = r.height - (n * speed) / 100;

        console.log(parallaxContainer)

    gsap.to('.icon-wrap.parallax-wrap', {
      scale: 2,
      ease:Power2.easeOut,
      duration:.3,
      // x: '40px',
      // y: '90px' 
    })
    gsap.to('.button-icon.parallax-element', {
      duration:.3,
      x: posx,
      y: posy,
      scale: .5
    })

    // stateTween.current && stateTween.current.kill();
    // stateTween.current = gsap
    // .timeline()
    // .to('#ball', {
    //   duration:.5,
    //   // x:a,
    //   // y:e,
    //   scale:.9,
    //   opacity:1,
    //   borderWidth:"2px",
    //   borderColor:"#000",
    // })

    // gsap.to('.icon-wrap.parallax-wrap', {
    //   scale: 2,
    //   ease:Power2.easeOut,
    //   duration:.3,
    //   x: '-20px',
    //   // x: i,
    //   // y: n
    // })
    //   gsap.to('.button-icon.parallax-element', {
    //   duration:.3,
    //   x: posx,
    //   y: posy,
    //   scale: .5
    // })

    // stateTween.current && stateTween.current.kill();
    // stateTween.current = gsap
    // .timeline()
    // .to(ball.current, {
    //     duration:.3,
    //     scale:1.35,
    //     borderWidth:"2px",
    //     backgroundColor: "transparent",
    //     borderColor: "#000",
    //     opacity:1
    // })
  } 


  // window.$('.socials li a').mouseenter(function(o){
  //   var t=window.$(this)[0].getBoundingClientRect(),
  //       i=(document.getElementById("ball-loader"),40),
  //       // a=t.right+i-10,
  //       // e=t.top+t.height/2;
  //       a=t.right - 40,
  //       e=t.top - 20

  //   console.log(t.right, a, t.top, e)

  //   gsap.to('.socials .parallax-wrap', {
  //     scale: 2,
  //     ease:Power2.easeOut,
  //     duration:.3,
  //     // x: '40px',
  //     // y: '90px' 
  //   })
  //   gsap.to('.socials .parallax-element', {
  //     duration:.3,
  //     x: a,
  //     y: e,
  //     scale: .5
  //   })
  // })


  return (
    <footer
      className="hidden"
      style={{ backgroundColor: props.bgColor }}>
      <div id="footer-container">
        <div id="backtotop" className="button-wrap left disable-drag">
          <div 
            className="icon-wrap parallax-wrap" 
            onClick={scrollUp} 
            onKeyDown={scrollUp} 
            aria-hidden="true"
            ref={wrap}
            // onMouseEnter={handleParallax}
            >
            <div 
              className="button-icon parallax-element" 
              ref={iconLeft}
              onMouseEnter={animateOn}
              onMouseLeave={animateOut}
              // onMouseMove={handleLeftParallax}
              >
              <i className="fa fa-angle-up"></i>
            </div>
          </div>
          <div className="button-text sticky left">
            <span data-hover="Back To Top" onClick={scrollUp} onKeyDown={scrollUp}
              aria-hidden="true">Back To Top</span>
          </div>
        </div>

        <div className="footer-middle">
          <div className="copyright">
            {new Date().getFullYear()} ©{" "}
            <a
              className="pf-link link--metis link"
              target="_blank"
              rel="noreferrer"
              href="https://www.hyped.com/"
            >
              HYPED, LLC
            </a>
            . All rights reserved.
          </div>
        </div>

        <div className="socials-wrap disable-drag">
          <div className="socials-icon">
            <i className="fa fa-share-alt" aria-hidden="true"></i>
          </div>
          <div className="socials-text">Follow Us</div>
          <ul className="socials">
            <li>
              <span className="parallax-wrap">
                <a
                  className="parallax-element"
                  href="https://dribbble.com/gethyped"
                  target="_blank"
                  rel="noreferrer"
                  onMouseEnter={animateOn}
                  onMouseLeave={animateOut}
                  // onMouseMove={handleRightParallax}
                >
                  Dribbble
                </a>
              </span>
            </li>
            <li>
              <span className="parallax-wrap">
                <a
                  target="_blank"
                  className="parallax-element"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/hyped-dot-com/about/"
                  onMouseEnter={animateOn}
                  onMouseLeave={animateOut}
                  // onMouseMove={handleRightParallax}
                >
                  Linkedin
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}