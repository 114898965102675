import React, { useEffect, useRef, useContext, useState } from "react";
import { Link } from "gatsby"
import "../css/button.scss";
import { GlobalContext } from "../providers/GlobalProvider";

const Button = (props) => {
  const splitRef = useRef();
  const { setPageScrollUp, setResetLottieLogo } = useContext(GlobalContext);
  const [formState, setFormState] = useState(null);

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      return
    };

    if (props.state && props.state.formType) {
      setFormState(props.state.formType)
    }
    showText();
  }, [])

  function showText() {
    const target = splitRef.current.querySelectorAll('.letter-split');
    window.Splitting({ target: target, by: "chars" });


    const btnEmoji = splitRef.current.querySelectorAll('.btnEmoji');
    btnEmoji.forEach((emoji, i) => {
      if (i === (btnEmoji.length - 1)) {
        emoji.style.marginRight = '10px';
      }
    })


    const mainContainer = splitRef.current.querySelectorAll('.main-btn-container');
    mainContainer.forEach(function (ele) {
      let w = 0;
      setTimeout(() => {
        ele.querySelectorAll('.btnEmoji').forEach((element, i) => {
          w += (element.offsetWidth);
        })

        if (w > 0) {
          w += 10;

          ele.querySelector(".letter-split.link-hover").style.left = `${w}px`
          ele.querySelector(".letter-split.link-hover").style.top = "5px"
          ele.querySelector(".letter-split.link-hover").style.width = "100%"
        }
      }, 200);
    });
  }
  return (
    <div className="animate-btn" ref={splitRef}>
      {props.ajax === "true" ? (
        <Link
          className={
            "ajax-link " + (props.ajaxId ? "main-btn-ajax" : "")
          }
          data-type="page-transition"
          to={props.url}
          id={props.ajaxId}
          style={{ color: props.color }}
          state={{ formType: formState }}
          onClick={() => { setPageScrollUp(true); setResetLottieLogo(true) }}
        >
          <div
            className="main-btn-container"
            style={{ backgroundColor: props.bgColor }}
          >
            <div className="main-btn">
              {props.emoji ? (
                <span className="btnEmoji" id={props.emojiId1}>
                  {props.emoji}
                </span>
              ) : (
                " "
              )}
              {props.emoji2 ? (
                <span className="btnEmoji" id={props.emojiId2}> {props.emoji2} </span>
              ) : (
                " "
              )}
              <span className="link-hover letter-split"> {props.text}</span>
              <span className="link-text letter-split">{props.text}</span>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className="main-btn-container"
          style={{ backgroundColor: props.bgColor }}
        >
          <div className="main-btn" style={{ color: props.color }}>
            {props.emoji ? (
              <span className="btnEmoji" id={props.emojiId1}>
                {props.emoji}
              </span>
            ) : (
              " "
            )}
            {props.emoji2 ? (
              <span className="btnEmoji" id={props.emojiId2}> {props.emoji2} </span>
            ) : (
              " "
            )}
            <span className="link-hover letter-split"> {props.text}</span>
            <span className="link-text letter-split"> {props.text}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Button;
