import React, { useRef, useLayoutEffect, useState, useContext } from "react";
import { GlobalContext } from "../providers/GlobalProvider";
import Lottie from "react-lottie";
import animationData from "../lotties/lottie-logo.json";
import { gsap, Power2 } from "gsap";
import "../css/preloader.css";

export default function Preloader() {
    const { preloaderLoaded, setPreloaderLoaded, setPreloaderComplete } = useContext(GlobalContext);
    const [isHidden, setIsHidden] = useState(false);

    const preloader = useRef();
    const preloaderAnimation = useRef();
    const preloaderOptions = {
        loop: false,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        },
    };

    useLayoutEffect(() => {
        const { anim } = preloaderAnimation.current;

        if (!preloaderLoaded) {
            anim.goToAndPlay(0, true);
            setPreloaderLoaded(true);
        } else {
            setIsHidden(true);
            anim.removeEventListener('complete', hidePreloader);
          }
          anim.addEventListener('complete', hidePreloader);
    }, []);

    function hidePreloader() {
        gsap.to(preloader.current, {
            duration: 1,
            force3D: true,
            yPercent: -101,
            delay: 0.02,
            ease: Power2.easeInOut,
        });
        gsap.set(preloader.current, {
            visibility: "hidden",
            delay: 1,
            opacity: 0,
            backgroundColor: "transparent",
        });
        setPreloaderComplete(true);
    }

    return(
        <div 
            ref={preloader} 
            className={`preloader-wrap ${isHidden ? "hidden" : null}`}
            data-firstline="" data-secondline=""
            >
          <div className="animation-container">
            <div id="lottie-container">
                <Lottie
                    ref={preloaderAnimation}
                    options={preloaderOptions}
                    speed={1.5}
                />
            </div>
          </div>
        </div>
    )
}

