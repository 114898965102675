import React, { useContext, useCallback } from 'react';
import { graphql, StaticQuery, Link } from "gatsby";
import { GlobalContext } from "../providers/GlobalProvider";
import Button from "../components/Button";
import Arrow from "../svgs/Arrow.svg";
import "../css/prefooter.css";


const Prefooter = () => {
    const { setPageScrollUp, setResetLottieLogo, setCursor } = useContext(GlobalContext);

    const toggleCursor = useCallback(() => {
        setCursor(({ active }) => ({ active: !active }));
    }, []);

    return (
        <section className="prefooter">
            <div className="main-wrapper" id="prefooter-container">
            <StaticQuery
                query={graphql`
                query PrefooterLinks {
                    links: allContentfulComponent(
                        filter: {subsection: {eq: "prefooter-links"}}
                        sort: {fields: order}
                    ) {
                        nodes {
                        headline
                        slug
                        link
                        }
                    }

                    text: contentfulComponent(slug: {eq: "prefooter-text"}) {
                        decorator
                        slug
                        headline
                        }

                        contact:   allContentfulComponent(
                        filter: {subsection: {eq: "prefooter-contact"}}
                        sort: {fields: order}
                        ) {
                        nodes {
                            headline
                            slug
                            asset {
                            file {
                                url
                            }
                            }
                            link
                        }
                        }
                    }
                `}
                render={data => (
                <div className="pf-row">
                    <div className="column">
                        <div className="pf-left">
                            <div className="prefooter-text">
                                <h2 className="decorator">
                                    {data.text.decorator}
                                </h2>
                                <h1 className="headline dark">
                                    {data.text.headline}
                                </h1>
                                {data.contact.nodes.map((item, index) => (
                                    <span className="contact phone" key={item.slug}>
                                        <img
                                            src={item.asset.file.url}
                                            alt={item.slug}
                                            />
                                        <a href={item.link}>
                                            {item.headline}
                                        </a>
                                    </span>
                                ))}
                            </div>
                            <Button text="Have A Project?" bgColor="#fff" color="#1a1f2c" ajax="true" url="/contact/" ajaxId="idea" emoji="👋" emojiId1="grow" state={{ formType: "idea" }}/>
                        </div>
                    </div>
                    <div className="column">
                        <div className="vc_row small prefooter-list" id="accordion-animation">
                            <div className="prefooter-links" data-delay="150">
                                {data.links.nodes.map((item, index) => (
                                <Link 
                                    to={item.link} 
                                    key={item.slug}
                                    id={item.slug}
                                    state={{ formType: item.slug }}
                                    onClick={() => { setPageScrollUp(true); setResetLottieLogo(true); setCursor({ active: false }) }}
                                    >
                                    <div
                                        className="link-wrapper"
                                        aria-hidden="true"
                                        
                                    >
                                        <span 
                                            id={item.slug} 
                                            onMouseEnter={toggleCursor}
                                            onMouseLeave={toggleCursor}
                                            className="link pf-link link--metis">{item.headline}</span>
                                        <div className="acc-button-icon parallax-element">
                                            <span><Arrow /></span>
                                        </div>
                                    </div>
                                </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                )}
                />
            </div>
        </section>
    )
}

export default Prefooter;